import { ParticipantStoryTopperProps } from "design-system/components/toppers/participant-story-topper/participant-story-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { ParticipantStoryTopperEntry } from "frontend/contentful/schema/toppers";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { useContentful } from "frontend/hooks/use-contentful";
import {
  ArticlePageEntry,
  ParticipantStoryEntry,
} from "frontend/contentful/schema/pages";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { getEntityName } from "../utils/get-entity-name";
import { LinkProps } from "design-system/components/primitives/link/link";
import { useTranslations } from "design-system/hooks/use-translations";

export interface ParticipantStoryTopperOptions {
  parentPage?: ArticlePageEntry;
}

export const createParticipantStoryTopperProps = createContentfulProps<
  ParticipantStoryTopperEntry | ParticipantStoryEntry,
  ParticipantStoryTopperProps,
  ParticipantStoryTopperOptions
>(({ entry, options, createEditAttributes }) => {
  const { getEntryUrl, getEntry, getEntries } = useContentful();
  const { t } = useTranslations();

  const props: ParticipantStoryTopperProps = {
    title: entry.fields.title,
    quote: entry.fields.quote ? (
      <RenderContentfulRichText
        document={entry.fields.quote}
        disableParagraphs
      />
    ) : undefined,
    infoItems: [],

    editAttributes: {
      quote: createEditAttributes({ entry, fieldId: "quote" }),
    },
  };

  const person = getEntry(entry.fields.person);
  const location = getEntry(entry.fields.location)?.fields.title;
  const mediaAsset = createMediaAssetProps(person?.fields.image);

  let role = entry.fields.role;

  if (!entry.fields.title) props.title = getEntityName(person);
  if (!entry.fields.role) {
    role = person?.fields.jobTitles?.join(", ");
  }
  if (mediaAsset?.image) {
    props.mediaAsset = mediaAsset;
    props.mediaAsset.aspectRatio = "1/1";
  }

  const industryNames = getEntries(options?.parentPage?.fields.industry)
    ?.map((industry) => industry.fields.title)
    .filter(Boolean) as Array<string>;
  const programLinks = getEntries(options?.parentPage?.fields.program)
    ?.map((program) => ({
      href: getEntryUrl(program),
      children: program.fields.title,
    }))
    .filter(Boolean) as Array<LinkProps>;

  if (role) {
    props.infoItems?.push({
      heading: t("role"),
      description: role,
      editAttribute: createEditAttributes({ entry, fieldId: "role" }),
    });
  }

  if (industryNames.length > 0) {
    props.infoItems?.push({
      heading: t("industry"),
      description: industryNames,
      editAttribute: createEditAttributes({ entry, fieldId: "industry" }),
    });
  }

  if (location) {
    props.infoItems?.push({
      heading: t("location"),
      description: location,
      editAttribute: createEditAttributes({ entry, fieldId: "location" }),
    });
  }

  if (programLinks) {
    props.infoItems?.push({
      heading: t("program"),
      description: programLinks,
      editAttribute: createEditAttributes({ entry, fieldId: "program" }),
    });
  }

  return props;
});
