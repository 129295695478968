import { BylineAuthor } from "design-system/components/primitives/byline/byline";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import {
  OrganizationEntry,
  PersonEntry,
  PersonWrapperEntry,
} from "frontend/contentful/schema/semantics";
import { createPersonImageProps } from "./media-asset.props";
import { useContentful } from "frontend/hooks/use-contentful";
import { isContentType } from "frontend/contentful/lib/is-content-type";

export const createBylineAuthorProps = createContentfulProps<
  PersonEntry | PersonWrapperEntry | OrganizationEntry,
  BylineAuthor
>(({ entry, createEditAttributes }) => {
  const { getPersonLink, getEntityName, getEntry } = useContentful();

  let authorEntry: PersonEntry | OrganizationEntry | undefined;

  if (isContentType<PersonWrapperEntry>("personWrapper", entry)) {
    authorEntry = getEntry(entry.fields.person);
  } else {
    authorEntry = entry;
  }

  if (
    !isContentType<PersonEntry>("person", authorEntry) &&
    !isContentType<OrganizationEntry>("organization", authorEntry)
  ) {
    return undefined;
  }

  const props: BylineAuthor = {
    name: getEntityName(entry),
    link: getPersonLink(entry),
    avatar: createPersonImageProps(entry),
    editAttributes: {
      name: createEditAttributes({ entry, fieldId: "firstName" }),
    },
  };

  return props;
});
