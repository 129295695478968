import { DashboardTopperProps } from "design-system/sites/alumni/dashboard-topper/dashboard-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { DashboardTopperEntry } from "frontend/contentful/schema/toppers";
import { useFetch } from "frontend/hooks/use-fetch";
import { AlumniUserProfile } from "frontend/types";
import { usePageProps } from "frontend/hooks/use-page-props";
import { useContentful } from "frontend/hooks/use-contentful";

export const createDashboardTopperProps = createContentfulProps<
  DashboardTopperEntry,
  DashboardTopperProps
>(() => {
  const { request } = usePageProps();
  const { site } = useContentful();

  const props: DashboardTopperProps = {
    title: "Welcome",
    hiddenTitle:
      request.path.substring(1) === site?.fields.slug ||
      (request.path === "/" && site?.fields.domain !== "www")
        ? site?.fields.title
        : undefined,

    items: [],
  };

  // Fetch Alumni data from API to populate the dashboard topper with personalized information
  const { data, error } = useFetch<AlumniUserProfile>({
    url: "/api/internal/alumni/me",
    params: {},
  });

  if (error) {
    props.title = "Sorry...";
    props.items = [
      {
        title: "Error",
        value:
          "There was an error loading the alumni data. Please try again later.",
      },
    ];
    return props;
  }

  if (!data) {
    return props;
  } else {
    const visibleClubsLength =
      data?.clubs.filter((club) => club.visible).length ?? 0;

    const classYear = data?.currentUserDetails?.details?.classYear;
    const degree = data?.currentUserDetails?.details?.degree;
    const section = data?.currentUserDetails?.details?.section;

    props.title = "Welcome back, " + data.displayName;
    props.items = [
      {
        title: "Current Company",
        value: data.currentEmployment?.organization?.organizationName ?? "N/A",
      },
      {
        title: "Current Role",
        value: data.currentEmployment?.jobTitle ?? "N/A",
      },
      {
        title: "Location",
        value: `${data.primaryHomeAddress?.city ?? ""}${data.primaryHomeAddress?.city ? ", " : ""}${data.primaryHomeAddress?.stateCode ?? ""}${data.primaryHomeAddress?.stateCode ? ", " : ""}${data.primaryHomeAddress?.country?.description ?? ""}`,
      },
      {
        title: "Interests",
        value: (
          <>
            {data.interests
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .slice(0, 1)
              .map((interest, index) => (
                <span key={index}>{interest.desc}</span>
              ))}
            {data.interests.length > 1 && (
              <>
                {", "}
                and {data.interests.length - 1}{" "}
                {data.interests.length > 3 ? "others" : "other"}
              </>
            )}
          </>
        ),
      },
      {
        title: "Clubs",
        value: (
          <>
            {data.clubs
              .filter((club) => club.visible)
              .slice(0, 1)
              .map((club, index) => (
                <span key={index}>{club.club.clubDescription}</span>
              ))}
            {visibleClubsLength > 1 && (
              <>
                {", "}
                and {visibleClubsLength - 1}{" "}
                {visibleClubsLength > 3 ? "others" : "other"}
              </>
            )}
          </>
        ),
      },
      {
        title: "HBS Affiliation",
        value: `${degree} ${classYear}${section ? ", Section " + section : ""}`,
      },
    ];

    props.info = {
      percentage: data.profilePercentComplete,
      href: "#",
    };
    props.cta = {
      href: "#",
      children: "Update Your Profile",
    };
  }

  return props;
});
