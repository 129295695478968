import { SearchTopperProps } from "design-system/components/toppers/search-topper/search-topper";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { SearchTopperEntry } from "frontend/contentful/schema/toppers";
import { usePageProps } from "frontend/hooks/use-page-props";
import { ModernSearchHit, SearchResults, Suggestions } from "frontend/types";
import { useState } from "react";
import {
  hasSearchData,
  setdropDownActive,
  setdropDownUrl,
} from "../utils/get-page-search-props";
import { useContentful } from "frontend/hooks/use-contentful";
import { fetcher } from "frontend/hooks/use-fetch";
import { createCtaLinkProps } from "../primitives/cta-link.props";

export const createSearchTopperProps = createContentfulProps<
  SearchTopperEntry,
  SearchTopperProps
>(({ entry, createEditAttributes }) => {
  const { getEntryUrl, site } = useContentful();

  const pageProps = usePageProps();
  const [dropdownSuggestions, setDropdownSuggestions] =
    useState<Suggestions["updatedSuggestions"]>();
  const [dropdownItems, setDropdownItems] = useState<Array<ModernSearchHit>>(
    [],
  );

  const props: SearchTopperProps = {
    theme: entry.fields.theme,
    title: entry.fields.title,
    placeholder: entry.fields.placeholder,
    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      placeholder: createEditAttributes({ entry, fieldId: "placeholder" }),
    },
  };

  if (entry.fields.searchDropdownType === "Suggestions") {
    const dropDownActive = setdropDownActive(pageProps);
    const url = setdropDownUrl(site, entry.fields.searchDropdownType);

    const handleSearch = async (term: string) => {
      if (!term || term === "" || term.length < 3) {
        setDropdownItems([]);
        setDropdownSuggestions({});
        return;
      }

      const data = (await fetcher({
        url: url,
        params: { term },
      })) as SearchResults;

      if (hasSearchData(data)) {
        if ("hits" in data) {
          setDropdownItems(data.hits.map((hit) => hit as ModernSearchHit));
        }
        if ("updatedSuggestions" in data && data.updatedSuggestions) {
          setDropdownSuggestions(data.updatedSuggestions);
        }
      }

      return;
    };

    let currentPageUrl = undefined;
    if (pageProps.currentPage?.url) {
      currentPageUrl = pageProps.currentPage.url;
      if (pageProps.currentPage?.url.indexOf(".hbs.edu") > -1) {
        currentPageUrl = pageProps.currentPage?.url.split(".hbs.edu")[1];
      }
    }

    props.searchDropdown = {
      placeholder: entry.fields.placeholder,
      dropdownFooterLabel: entry.fields.searchDropdownFooterLabel,
      hasDropdown: dropDownActive,
      searchLink: getEntryUrl(entry.fields.searchDropdownCta) || currentPageUrl,
      dropdownCta: createCtaLinkProps(entry.fields.searchDropdownCta),
      handleSearch: (term: string) => {
        void handleSearch(term);
      },
      dropdownItems,
      dropdownSuggestions,
      site: site,
    };
  }

  return props;
});
