import { SiteHeaderProps } from "design-system/components/universal/site-header/site-header";
import { createNavigationItemProps } from "frontend/contentful/components/universal/navigation-item.props";
import { getSiteHeaderTheme } from "frontend/contentful/components/utils/get-site-header-theme";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import {
  ClubPageEntry,
  KnowledgeBaseArticlePageEntry,
  PageEntry,
  ParticipantStoryEntry,
} from "frontend/contentful/schema/pages";
import {
  ExpandedMenuEntry,
  LinkListEntry,
} from "frontend/contentful/schema/primitives";
import { SiteEntry } from "frontend/contentful/schema/universal";
import { useContentful } from "frontend/hooks/use-contentful";
import { fetcher, useFetch } from "frontend/hooks/use-fetch";
import { usePageProps } from "frontend/hooks/use-page-props";
import { getSiteId } from "frontend/lib/get-site-id";
import { ModernSearchHit, SearchResults, Suggestions } from "frontend/types";
import { useSearchParams } from "next/navigation";
import { Dispatch, SetStateAction, useState } from "react";
import { createLazyTopperProps } from "../toppers/lazy-topper.props";
import { hasSearchData, setdropDownUrl } from "../utils/get-page-search-props";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createExpandedMenuProps } from "./expanded-menu-item.props";
import { createGlobalMenuNavItemProps } from "./global-menu-nav-item.props";
import { createSocialMediaLinksProps } from "./social-media-links.props";

type SetState<T> = Dispatch<SetStateAction<T>>;

interface MincAPIResponse {
  result: Array<MincResponseData>;
}

interface MincResponseData {
  sys_updated_on: string;
  type: string;
  details: string;
  cmdb_ci: {
    display_value: string;
    link: string;
  };
  end: string;
  begin: string;
}

type ModernSearchHits = Array<ModernSearchHit>;
type UpdatedSuggestions = Suggestions["updatedSuggestions"];
type HandleSearch = (props: {
  term: string;
  setDropdownItems: SetState<ModernSearchHits>;
  setDropdownSuggestions: SetState<UpdatedSuggestions>;
  hasSearchPage: string | undefined;
  url: string;
  entry: SiteEntry;
}) => Promise<void>;
const handleSearch: HandleSearch = async ({
  term,
  setDropdownItems,
  setDropdownSuggestions,
  url,
  entry,
  hasSearchPage,
}) => {
  if (term.length < 3 || !term || term === "") {
    setDropdownItems([]);
    return;
  }
  if (term.length < 4 || !term || term === "") {
    setDropdownSuggestions({});
    return;
  }

  const site = getSiteId(entry);

  const data = (await fetcher({
    url: url,
    params: { term, site, hasSearchPage },
  })) as SearchResults | Suggestions;

  if (hasSearchData(data)) {
    if ("hits" in data) {
      setDropdownItems(data.hits.map((hit) => hit as ModernSearchHit));
    }
    if ("updatedSuggestions" in data && data.updatedSuggestions) {
      setDropdownSuggestions(data.updatedSuggestions);
    }
  }
  return;
};

export const createSiteHeaderProps = createContentfulProps<
  SiteEntry,
  SiteHeaderProps
>(({ entry, createEditAttributes }) => {
  const searchParams = useSearchParams();
  const domain = entry.fields.domain;

  const pageProps = usePageProps();
  const { getEntryUrl, getEntry, getEntries } = useContentful();
  const [dropdownItems, setDropdownItems] = useState<ModernSearchHits>([]);
  const [dropdownSuggestions, setDropdownSuggestions] =
    useState<UpdatedSuggestions>();
  const site = getEntry(pageProps.site);
  const url = setdropDownUrl(site);
  const hasSearchPage = getEntry(pageProps.searchPage)?.fields.url;

  const header = getEntry(entry.fields.header);
  if (!header) return;

  const siteUrl = `/${entry.fields.slug && entry.fields.slug !== "/" ? entry.fields.slug : ""}`;

  const siteType = entry.fields.domain === "my" ? "internal" : "external";
  const enableLogin = entry.fields.enableLogin;
  const enableLogout =
    pageProps.pagePermissions || pageProps.sitePermissions || enableLogin
      ? true
      : false;
  const globalMenu = getEntry(header.fields.globalMenu);
  const organization = getEntry(globalMenu?.fields.organization);
  const page = pageProps.entry as PageEntry | undefined;
  const isWWWSearchPage =
    page && page.fields.slug === "search" && !page.fields.parentPageId;
  const isParticpantStory = isContentType<ParticipantStoryEntry>(
    "participantStory",
    page,
  );
  const isKnowledgeBaseArticlePage =
    isContentType<KnowledgeBaseArticlePageEntry>(
      "knowledgeBaseArticlePage",
      page,
    );
  const isClubPage = isContentType<ClubPageEntry>("clubPage", page);
  const topper = createLazyTopperProps(page?.fields.topper);
  const theme = isParticpantStory
    ? "dark"
    : isKnowledgeBaseArticlePage
      ? page.fields.topperTheme
      : isClubPage
        ? "dark"
        : getSiteHeaderTheme(topper);

  const props: SiteHeaderProps = {
    domain: domain,
    theme,
    localNavigationItems: [],
    expandedNavigationItems: [],
    isHomepage: pageProps.singletons.isWWWHomePage || isWWWSearchPage,
    globalMenu: {
      id: "global-menu",
      label: "Quick links",
      socialLinks: createSocialMediaLinksProps(organization)?.links,
      items: [],
    },
    searchLink: getEntryUrl(pageProps.searchPage),
    hasSearchOverlay: true,
    search: {
      dropdownItems,
      dropdownSuggestions,
      hasDropdown: true,
      dropdownCta: {
        children: "View Results",
        href: getEntryUrl(pageProps.searchPage),
      },
      handleSearch: (term: string) => {
        void handleSearch({
          term,
          setDropdownItems,
          setDropdownSuggestions,
          url,
          entry,
          hasSearchPage,
        });
      },
    },
    editAttributes: {
      title: createEditAttributes({ entry: entry, fieldId: "title" }),
      moreItemsTitle: createEditAttributes({
        entry: header,
        fieldId: "moreItemsTitle",
      }),
    },
  };

  if (entry.fields.title) {
    props.sectionTitle = { title: entry.fields.title, url: siteUrl };
  }

  //headerBanner only appears on my and Alumni sites when user is logged in.
  if (pageProps.session) {
    props.session = pageProps.session;
    if (
      (entry.fields.domain === "my" || entry.fields.domain === "alumni") &&
      pageProps.session.user?.loggedIn &&
      pageProps.session.user?.id !== "anonymous"
    ) {
      props.headerBanner = {
        title: "Hello, " + pageProps.session.user?.displayName,
        link:
          entry.fields.domain === "alumni"
            ? { children: "Account", href: "#" }
            : {},
        notificationHref: entry.fields.domain === "alumni" ? "#" : "",
      };
    }
  }

  getEntries(header.fields.links)?.forEach((link) => {
    if (isContentType<LinkListEntry>("linkList", link)) {
      const linkProps = createNavigationItemProps(link);
      if (linkProps) props.localNavigationItems?.push(linkProps);
    } else if (isContentType<ExpandedMenuEntry>("expandedMenu", link)) {
      const expandedMenuProps = createExpandedMenuProps(link);
      if (expandedMenuProps) {
        props.expandedNavigationItems?.push(expandedMenuProps);
      }
    }
  });

  getEntries(globalMenu?.fields.links)?.forEach((link) => {
    const item = createGlobalMenuNavItemProps(link);
    if (item) props.globalMenu?.items?.push(item);
  });

  const alert = getEntry(header.fields.alert);

  if (alert?.fields.apiSource === "Manual") {
    props.headerAlert = {
      title: alert.fields?.title,
      href: getEntryUrl(alert.fields?.link),
      description: (
        <RenderContentfulRichText document={alert.fields.description} />
      ),
      editAttributes: {
        url: createEditAttributes({ entry: alert.fields.link, fieldId: "url" }),
        title: createEditAttributes({
          entry: header.fields.alert,
          fieldId: "title",
        }),
        description: createEditAttributes({
          entry: header.fields.alert,
          fieldId: "description",
        }),
      },
    };
  } else if (alert?.fields.apiSource === "ServiceNow MINC") {
    /* alert for IT MINCs
    https://www.hbs.edu/api/servicenow/minc
    mock data to test https://www.hbs.edu/api/servicenow/minc?mock=1 */

    const mockParam = searchParams.get("mock");
    const fetchParams = mockParam ? { mock: mockParam } : {};

    const { data } = useFetch<MincAPIResponse>({
      url: "/api/servicenow/minc",
      params: fetchParams,
    });

    if (data?.result?.length) {
      const item = data.result[0];

      if (item?.type === "Service Interruption") {
        props.headerAlert = {
          title: entry.fields.title,
          href: "https://hbs.service-now.com/support",
          description:
            "We are currently experiencing a service interruption related to " +
            item?.cmdb_ci.display_value +
            ".  Please refer to the IT Service Portal for additional details.",
          theme: "red",
        };
      }
    }
  }

  const contentReferences = getEntry(pageProps.contentReferences);
  const callToAction = getEntry(header.fields.callToAction);
  const callToActionUrl = getEntryUrl(callToAction);
  const isExternal = callToAction?.fields.isExternalUrl || false;
  if (callToAction) {
    // Use redirect only if an external links
    const link =
      contentReferences?.fields.programFormRedirect &&
      callToActionUrl &&
      isExternal
        ? contentReferences.fields.programFormRedirect +
          "?initurl=" +
          callToActionUrl
        : callToActionUrl;
    props.cta = {
      text: callToAction.fields.text,
      link: link,
    };
  }

  if (header.fields.moreItemsTitle) {
    props.moreItemsTitle = header.fields.moreItemsTitle;
  }

  if (site && site.fields.title === "Working Knowledge") {
    props.isWorkingKnowledge = true;
    props.workingKnowledgeTagline = header.fields.tagline;
    props.workingKnowledgeTitleLink =
      "https://www.library.hbs.edu/working-knowledge";
  }
  let logInOutText;
  let logInOutLink;
  if (enableLogin && !pageProps.session?.user.loggedIn) {
    logInOutText = "Log In";
    logInOutLink = pageProps.session?.loginUrl;
  } else if (pageProps.session?.user.loggedIn && enableLogout) {
    logInOutText = "Log Out";
    logInOutLink = pageProps.session?.logoutUrl;
  }

  if (logInOutText) {
    props.logInOut = {
      text: logInOutText,
      children: logInOutText,
      link: logInOutLink,
      href: logInOutLink,
      hideIcon: false,
    };
  } else {
    props.logInOut = undefined;
  }

  props.siteType = siteType;
  props.enableLogout = enableLogout;

  if (domain === "online") {
    props.logoLink = "https://online.hbs.edu";
  } else if (siteType === "internal") {
    props.logoLink = "https://inside.hbs.edu/myhbs.aspx";
  } else {
    props.logoLink = "https://www.hbs.edu";
  }

  return props;
});
