import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import {
  PersonEntry,
  PersonWrapperEntry,
} from "frontend/contentful/schema/semantics";
import { createPersonImageProps } from "../primitives/media-asset.props";
import { useContentful } from "frontend/hooks/use-contentful";
import { RelatedPerson } from "design-system/components/pages/article-page/article-related-people/article-related-people";
import { isContentType } from "frontend/contentful/lib/is-content-type";

export const createFeaturedFacultyProps = createContentfulProps<
  PersonEntry | PersonWrapperEntry,
  RelatedPerson
>(({ entry, createEditAttributes }) => {
  const { getPersonLink, getEntityName, getEntry } = useContentful();
  const props: RelatedPerson = { name: "", titles: [] };

  if (entry) {
    const personEntry = isContentType<PersonWrapperEntry>(
      "personWrapper",
      entry,
    )
      ? getEntry(entry.fields.person)
      : entry;
    const mediaAsset = createPersonImageProps(entry);

    // force 1:1 aspect ratio
    if (mediaAsset?.image) {
      mediaAsset.image.width = "400px";
      mediaAsset.image.height = "400px";
    }

    props.name = getEntityName(entry);
    props.titles = personEntry?.fields.jobTitles || [""];
    props.portrait = mediaAsset ? mediaAsset : undefined;
    props.link = getPersonLink(entry);

    props.editAttributes = {
      name: createEditAttributes({ entry: personEntry, fieldId: "firstName" }),
      titles: createEditAttributes({
        entry: personEntry,
        fieldId: "jobTitles",
      }),
    };
    return props;
  }
});
