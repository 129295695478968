import {
  BylineAuthor,
  BylineProps,
} from "design-system/components/primitives/byline/byline";
import {
  ArticlePageEntry,
  PodcastEpisodePageEntry,
} from "frontend/contentful/schema/pages";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { createBylineAuthorProps } from "../primitives/byline-author.props";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { useContentful } from "frontend/hooks/use-contentful";

export const createBylineProps = createContentfulProps<
  ArticlePageEntry | PodcastEpisodePageEntry,
  BylineProps
>(({ entry, createEditAttributes }) => {
  const { getIsPersonPastHbsFaculty } = useContentful();

  const props: BylineProps = {
    type: "ShowAll",
    publicationDate: entry.fields.publicationDate,
    editAttributes: {
      publicationDate: createEditAttributes({
        entry,
        fieldId: "publicationDate",
      }),
    },
  };

  props.authors = [];

  if (isContentType<ArticlePageEntry>("articlePage", entry)) {
    entry.fields.authors?.forEach((person) => {
      const bylinePersonProps = createBylineAuthorProps(person);
      if (bylinePersonProps) props.authors?.push(bylinePersonProps);
    });
    if (props.authors.length === 0 && entry.fields.legacyStoryBylineTypein) {
      const legacyAuthorByline: BylineAuthor = {
        name: entry.fields.legacyStoryBylineTypein,
      };
      props.authors.push(legacyAuthorByline);
    }
    entry.fields.relatedOrganizations?.forEach((organization) => {
      const bylinePersonProps = createBylineAuthorProps(organization);
      if (bylinePersonProps) props.authors?.push(bylinePersonProps);
    });
  }

  props.featuredAuthors = [];
  entry.fields.relatedFaculty?.forEach((person) => {
    if (getIsPersonPastHbsFaculty(person)) return;
    const bylinePersonProps = createBylineAuthorProps(person);
    if (bylinePersonProps) props.featuredAuthors?.push(bylinePersonProps);
  });

  if (props.featuredAuthors?.length > 0) props.headshots = true;

  return props;
});
