import {
  Theme,
  getSplitTopperTheme,
  getLargeAssetTopperTheme,
  SiteHeaderTheme,
  defaultPageSectionTheme,
} from "design-system/utils/theme";

import { LazyComponentProps } from "design-system/components/system/lazy-component/lazy-component";

const defaultSiteHeaderTheme = defaultPageSectionTheme;

export function getSiteHeaderTheme(
  topper: LazyComponentProps | undefined,
): SiteHeaderTheme {
  // infer theme from topper theme if exists, otherwise use default theme
  if (topper?.name === "CardTopper") {
    return topper?.props?.theme || defaultSiteHeaderTheme;
  }

  // default to dark theme for these toppers
  if (
    topper?.name === "ParticipantStoryTopper" ||
    topper?.name === "DashboardTopper"
  ) {
    return "dark";
  }

  // infer theme from topper spot theme if exists

  if (topper?.name === "SplitTopper") {
    if (topper?.props?.spotTheme) {
      return getSplitTopperTheme(topper?.props?.spotTheme);
    }
  }

  if (topper?.name === "LargeAssetTopper") {
    return getLargeAssetTopperTheme(topper?.props?.spotTheme);
  }

  // grab theme from topper if exists
  if (topper?.props && "theme" in topper.props && topper?.props?.theme) {
    return topper.props.theme as Theme;
  }

  return defaultSiteHeaderTheme;
}
