import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import {
  ExpandedMenuEntry,
  PageListAcceptedLinks,
  PageListEntry,
} from "frontend/contentful/schema/primitives";

import { MediaAssetProps } from "design-system/components/primitives/media-asset/media-asset";
import {
  ExpandedLocalNavigationItemProps,
  ExpandedNavigationLinkWithSubItems,
  ExpandedNavigationSubMenuProps,
} from "design-system/components/universal/expanded-local-navigation/local/expanded-local-navigation";
import { NavigationLink } from "design-system/components/universal/local-navigation/local-navigation";
import { filterUndefined } from "design-system/utils/filter-undefined";
import { documentToString } from "frontend/contentful/lib/document-to-string";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import {
  ArchivePageEntry,
  ArticlePageEntry,
  ClubPageEntry,
  DatabasePageEntry,
  DatasetPageEntry,
  DetailPageEntry,
  KnowledgeBaseArticlePageEntry,
  LandingPageEntry,
  ParticipantStoryEntry,
  PodcastEpisodePageEntry,
  ProfilePageEntry,
  ProgramPageEntry,
} from "frontend/contentful/schema/pages";
import { useContentful } from "frontend/hooks/use-contentful";
import { createCtaLinkProps } from "../primitives/cta-link.props";
import { createMediaAssetProps } from "../primitives/media-asset.props";

type FeatureLinks = NavigationLink & { mediaAsset?: MediaAssetProps };

type PageListAcceptedEntries =
  | ArchivePageEntry
  | ArticlePageEntry
  | ClubPageEntry
  | DatabasePageEntry
  | DatasetPageEntry
  | DetailPageEntry
  | KnowledgeBaseArticlePageEntry
  | LandingPageEntry
  | ParticipantStoryEntry
  | PodcastEpisodePageEntry
  | ProfilePageEntry
  | ProgramPageEntry;

const getPageValues = (item: PageListAcceptedEntries): FeatureLinks => {
  if (isContentType<ArticlePageEntry>("articlePage", item)) {
    const { title, url, description, shortDescription, teaseImage, leadImage } =
      item.fields;

    const mediaAsset = leadImage
      ? createMediaAssetProps(leadImage)
      : createMediaAssetProps(teaseImage);

    const descriptionText = shortDescription
      ? documentToString(shortDescription)
      : documentToString(description);

    return {
      title: title || "",
      description: descriptionText,
      url: url,
      mediaAsset,
    };
  }

  if (
    isContentType<DatabasePageEntry>("databasePage", item) ||
    isContentType<DatasetPageEntry>("datasetPage", item)
  ) {
    const { title, url, description, shortDescription } = item.fields;

    const descriptionText = shortDescription
      ? documentToString(shortDescription)
      : documentToString(description);

    return {
      title: title || "",
      description: descriptionText,
      url: url,
    };
  }

  if (isContentType<PodcastEpisodePageEntry>("podcastEpisodePage", item)) {
    const { title, url, description, subtitle, relatedImage } = item.fields;
    const descriptionText = documentToString(description) || subtitle;

    const mediaAsset = createMediaAssetProps(relatedImage);

    return {
      title: title || "",
      description: descriptionText,
      url: url,
      mediaAsset,
    };
  }

  if (isContentType<ProgramPageEntry>("programPage", item)) {
    const { title, url, description, media } = item.fields;
    const descriptionText = documentToString(description);

    const mediaAsset = createMediaAssetProps(media);

    return {
      title: title || "",
      description: descriptionText,
      url: url,
      mediaAsset,
    };
  }

  if (
    isContentType<KnowledgeBaseArticlePageEntry>(
      "knowledgeBaseArticlePage",
      item,
    )
  ) {
    const { title, url, subtitle, shortDescription } = item.fields;
    const descriptionText = documentToString(subtitle) || shortDescription;

    return {
      title: title || "",
      description: descriptionText,
      url: url,
    };
  }

  if (isContentType<ParticipantStoryEntry>("participantStory", item)) {
    const { title, url, subTitle, shortDescription, image } = item.fields;
    const descriptionText = subTitle || documentToString(shortDescription);

    const mediaAsset = createMediaAssetProps(image);

    return {
      title: title || "",
      description: descriptionText,
      url: url,
      mediaAsset,
    };
  }

  if (isContentType<ClubPageEntry>("clubPage", item)) {
    const { title, url, image } = item.fields;

    const mediaAsset = createMediaAssetProps(image);

    return {
      title: title || "",
      description: undefined,
      url: url,
      mediaAsset,
    };
  }

  /*
  The rest. So far:
  - ArchivePageEntry
  - DetailPageEntry
  - LandingPageEntry
  - ProfilePageEntry
  */
  return {
    title: item.fields.title || "",
    description: undefined,
    url: item.fields.url,
  };
};

export const createExpandedMenuProps = createContentfulProps<
  ExpandedMenuEntry,
  ExpandedLocalNavigationItemProps
>(({ entry: cfEntry }) => {
  const { getEntry } = useContentful();

  const mapToNavigationLink = (
    items: Array<PageListAcceptedLinks> | undefined,
  ): Array<FeatureLinks> => {
    return (
      items
        ?.map((itemsEntry) => {
          const item = getEntry(itemsEntry);
          if (!item?.fields) return undefined;
          return getPageValues(item);
        })
        .filter(filterUndefined) || []
    );
  };

  const transform = (expandedMenuEntry: ExpandedMenuEntry) => {
    const props: ExpandedLocalNavigationItemProps = {
      title: expandedMenuEntry.fields.title || "",
      layoutType: "vertical",
      linkTitle: expandedMenuEntry.fields.linkTitle || "",
      url: expandedMenuEntry.fields.url || "",
      pageGroups: [],
    };

    if (expandedMenuEntry.fields.cta) {
      const cta = getEntry(expandedMenuEntry.fields.cta);
      props.cta = createCtaLinkProps(cta);
    }

    const { menu: pageGroupEntries } = expandedMenuEntry.fields;

    if (!pageGroupEntries?.length) {
      return;
    }

    if (pageGroupEntries.length > 1) {
      props.layoutType = "horizontal";
    }

    const pageGroups = pageGroupEntries
      .map((pageGroupEntry) => {
        const pageGroup = getEntry(pageGroupEntry);
        if (!pageGroup) return undefined;

        const { menu: pageListEntries, title } = pageGroup.fields;

        if (!pageListEntries) return undefined;

        const pageListOrLinks = pageListEntries
          .map((pageListEntry) => {
            const pageList = getEntry(pageListEntry);
            if (!pageList) return undefined;

            if (isContentType<PageListEntry>("pageList", pageList)) {
              const {
                title,
                featureCard,
                featureDescription,
                featureLinks,
                featureLinksTitle,
                featureMedia,
                menu,
                topLinks,
              } = pageList.fields;

              const result: ExpandedNavigationLinkWithSubItems = {
                title,
                featureCard,
                featureDescription,
                featureLinksTitle,
                featureMedia,
                featureLinks: mapToNavigationLink(featureLinks),
                menu: mapToNavigationLink(menu),
                topLinks: mapToNavigationLink(topLinks),
              };

              return result;
            } else {
              // Not a pageList, but a Link.
              const link = pageList;
              const item = getEntry(link);
              if (!item?.fields) return undefined;
              return getPageValues(item);
            }
          })
          .filter(filterUndefined);

        const result: ExpandedNavigationSubMenuProps = {
          title,
          pageListOrLinks,
        };

        return result;
      })
      .filter(filterUndefined);

    props.pageGroups = pageGroups;

    return props;
  };

  return transform(cfEntry);
});
